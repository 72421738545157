import React, { useEffect } from "react";
import {
  FloatingHelper,
  ToggleSwitch,
  FormField,
  EmptyState,
  CustomModalLayout,
  TextButton,
  Heading,
  Modal,
  MessageModalLayout,
  Text,
  Card,
  Box,
  Page,
  Button,
  Cell,
  Layout,
  Input,
  WixDesignSystemProvider,
  Image,
  PageSection,
  Badge,
  MarketingLayout,
  AnnouncementModalLayout,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import * as Icons from "@wix/wix-ui-icons-common";
import "./App.css";
import { ReactTagManager } from "react-gtm-ts";
import { useTranslation } from "react-i18next";
const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

function App() {
  const { t } = useTranslation()
  ReactTagManager.init(tagManagerArgs);
  const [isHighlighted, setIsHighlighted] = React.useState(false);
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [isOpening, setIsOpening] = React.useState(false);
  const [shownSettingsModal, setShownSettingsModal] = React.useState(false);
  const [clientId, setClientId] = React.useState("");
  const [clientSecret, setClientSecret] = React.useState("");
  const [showVideo, setShowVideo] = React.useState(false);
  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  });

  useEffect(() => {
    setIntercomIdentity();
    setInstance();
    getSettings();
  }, []);

  const token = new URLSearchParams(window.location.search).get("token");
  const instance = new URLSearchParams(window.location.search).get("instance");

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=e7523215-1027-44b7-8ed6-2a1d5f6af4e7&redirectUrl=https://certifiedcode.editorx.io/paypal-pay-button/_functions/@certifiedcode/base-backend/auth`;
  }

  // if (!instance) {
  //   return null
  // }

  const BASE_URL = `https://certifiedcode.editorx.io/paypal-pay-button/_functions`;

  function setIntercomIdentity() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/intercom", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.hash && data.email) {
          window.Intercom("boot", {
            email: data.email,
            user_hash: data.hash,
          });
        }
      })
      .catch(() => {});
  }

  function setInstance() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/instance", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setInstanceData(data);
        setIsUpgraded(data.instance.isFree === false);
      })
      .catch(() => {});
  }

  function setSettings() {
    // if (isUpgraded) {
    if (!open) {
      if (clientId === "" || clientSecret === "") {
        setShownSettingsModal(true);
        return;
      }
    }
    const account =
      !open === true
        ? {
            clientId,
            clientSecret,
          }
        : {};
    setIsOpening(true);
    fetch(BASE_URL + "/settings", {
      method: "POST",
      headers: {
        Authorization: instance || "",
      },
      body: JSON.stringify({
        isEnabled: !open,
        ...account,
      }),
    })
      .then(() => {
        setShownSettingsModal(false);
        setIsOpening(false);
        setOpen(!open);
        setClientId("");
        setClientSecret("");
      })
      .catch(() => {});
    // } else {
    //   setIsUpgradeModalOpen(true)
    // }
  }

  function getSettings() {
    setIsOpening(true);
    fetch(BASE_URL + "/settings", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setIsOpening(false);
        setOpen(data.isEnabled);
      })
      .catch(() => {});
  }

  const isPremiumApp = true;

  const YoutubeEmbed = ({ embedId }: any) => (
    <iframe
      width="560px"
      height="315px"
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title={t('youtube-title')}
    />
  );

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Page height="100vh">
        <Page.Header
          title={
            <Box gap={"SP2"} alignItems="middle">
              <Heading>{t('paypal-title')}</Heading>
            </Box>
          }
          actionsBar={
            <Box gap={"SP2"} direction="horizontal">
              {isPremiumApp ? (
                <Button
                  skin="premium"
                  prefixIcon={<Icons.PremiumFilled />}
                  onClick={() => setIsUpgradeModalOpen(true)}
                >
                  {isUpgraded ? t('manage-plan') : t('accept-button')}
                </Button>
              ) : (
                <></>
              )}
            </Box>
          }
        />
        <Page.Content>
          <Modal isOpen={showVideo}>
            <AnnouncementModalLayout
              title={t('getting-started')}
              onCloseButtonClick={() => {
                setShowVideo(false);
              }}
            >
              <YoutubeEmbed embedId="6lqD56HYkok" />
            </AnnouncementModalLayout>
          </Modal>
          <Modal
            isOpen={shownSettingsModal}
            onRequestClose={() => setShownSettingsModal(false)}
            shouldCloseOnOverlayClick
            screen="desktop"
          >
            <CustomModalLayout
              primaryButtonText={t('primaryButtonText')}
              secondaryButtonText={t('secondaryButtonText')}
              onCloseButtonClick={() => setShownSettingsModal(false)}
              secondaryButtonOnClick={() => setShownSettingsModal(false)}
              primaryButtonOnClick={() => setSettings()}
              removeContentPadding
              onHelpButtonClick={() => {
                window.open(
                  "https://stripe.com/docs/keys#reveal-an-api-secret-key-live-mode",
                  "_blank"
                );
              }}
              title={t('connect-paypal')}
              content={
                <Page>
                  <Page.Content>
                    <Box marginTop={5} display="block">
                      <Layout>
                        <Cell span={12}>
                          <Box direction="vertical" gap="SP2">
                            <FormField
                              label={t('client-label')}
                              suffix={
                                <TextButton
                                  as="a"
                                  href="https://support.certifiedcode.us/en/articles/8397855-setup-paypal-pay-button"
                                  target="_blank"
                                  size="small"
                                  suffixIcon={<Icons.ExternalLinkSmall />}
                                >
                                  {t('find-button')}
                                </TextButton>
                              }
                            >
                              <Input
                                clearButton
                                value={clientId}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setClientId(e.target.value)}
                              />
                            </FormField>
                            <FormField
                              label={t('secret-label')}
                              suffix={
                                <TextButton
                                  as="a"
                                  href="https://support.certifiedcode.us/en/articles/8397855-setup-paypal-pay-button"
                                  target="_blank"
                                  size="small"
                                  suffixIcon={<Icons.ExternalLinkSmall />}
                                >
                                  {t('find-button')}
                                </TextButton>
                              }
                            >
                              <Input
                                clearButton
                                value={clientSecret}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  setClientSecret(e.target.value)
                                }
                              />
                            </FormField>
                          </Box>
                        </Cell>
                      </Layout>
                    </Box>
                  </Page.Content>
                </Page>
              }
            />
          </Modal>
          <Modal
            isOpen={isUpgradeModalOpen}
            shouldCloseOnOverlayClick={true}
            onRequestClose={() => setIsUpgradeModalOpen(false)}
          >
            <AnnouncementModalLayout
              theme="premium"
              title={t('payment-title')}
              primaryButtonText={t('upgrade-button')}
              // linkText="Learn more"
              primaryButtonOnClick={() => {
                window.open(
                  `https://www.wix.com/apps/upgrade/${"e7523215-1027-44b7-8ed6-2a1d5f6af4e7"}?appInstanceId=${
                    (instanceData as any)["instance"]["instanceId"]
                  } `
                );
              }}
              // linkOnClick={() => { window.open('https://support.certifiedcode.us/en/articles/7953534-getting-started-PayPal-pay-button#h_b92f8de467') }}
              onCloseButtonClick={() => setIsUpgradeModalOpen(false)}
            >
              <Text>
                {t('payment-text')}
              </Text>
            </AnnouncementModalLayout>
          </Modal>
          <Modal isOpen={isModalOpen} screen="desktop">
            <MessageModalLayout
              primaryButtonText={t('refresh-button')}
              primaryButtonOnClick={() => window.location.reload()}
              title={t('error-title')}
              content={
                <Text>{t('refresh-title')}</Text>
              }
            />
          </Modal>
          <Layout>
            <Cell span={12}>
              <Card>
                <Card.Header
                  title={
                    <Box gap={"SP2"}>
                      <Heading size="medium" as="h2">
                        {t('online-heading')}
                      </Heading>
                      {isUpgraded ? (
                        <Badge size="tiny" skin={"success"}>
                          {t('ready-badge')}
                        </Badge>
                      ) : (
                        <FloatingHelper
                          target={
                            <Badge size="tiny" skin={"danger"}>
                              {t('limited-badge')}
                            </Badge>
                          }
                          content={
                            <FloatingHelper.Content
                              title={t('helper-title')}
                              body={t('helper-body')}
                            />
                          }
                          placement="right"
                        />
                      )}
                    </Box>
                  }
                  suffix={
                    <ToggleSwitch
                      onChange={() => {
                        setSettings();
                      }}
                      size="medium"
                      checked={open}
                      disabled={isOpening}
                    />
                  }
                />
                {/* <Card.Subheader title={<Text size='small'>Pay Button can be placed everywhere on your site via Editor, and supports custom amount or pre-defined amount payment. <b>Note: Pay Button does not integrate with Wix eCommerce.</b></Text>} suffix={
                  <Button skin='light' size="small" onClick={() => { setIsHighlighted(true) }}>Explore advanced integration</Button>
                } /> */}
                <Card.Content>
                  {open && isUpgraded ? (
                    <EmptyState
                      theme="section"
                      title={t('live-title')}
                      subtitle={t('live-subtitle')}
                    >
                      <TextButton
                        prefixIcon={<Icons.ExternalLink />}
                        as={"a"}
                        href={
                          (instanceData as any)["site"]
                            ? (instanceData as any)["site"]["url"]
                            : ""
                        }
                        target={"_blank"}
                      >
                        {t('live-button')}
                      </TextButton>
                    </EmptyState>
                  ) : (
                    <></>
                  )}
                </Card.Content>
              </Card>
            </Cell>
            <Cell>
              <PageSection title={t('suggested-title')} showDivider />
            </Cell>
            {/* <Cell span={6}>
              <Card>
                <MarketingLayout
                  title="Learn how to use PayPal Pay Button"
                  description="Discover how to use PayPal Pay Button to accept payments on your site."
                  actions={
                    <Button size="small" skin="standard" onClick={() => {
                      setShowVideo(true);
                    }}>Watch Video</Button>}
                  size="tiny"
                  badge={<Badge size="small">Tutorial Video</Badge>}
                  image={
                    <Box width="100%" align="right">
                      <Image
                        width="120px"
                        src="https://www.wix-pages.com/wix-design-system-employees/generic_upgrade.svg"
                        transparent
                      />
                    </Box>
                  }
                />
              </Card>
            </Cell> */}
            <Cell span={6}>
              <Card>
                <MarketingLayout
                  title={t('contact-title')}
                  description={t('contact-description')}
                  actions={
                    <Button
                      size="small"
                      onClick={() => {
                        window.Intercom(
                          "showNewMessages",
                          t('contact-msg')
                        );
                      }}
                    >
                      {t('chat-button')}
                    </Button>
                  }
                  size="tiny"
                  hiddenBadge
                  alignItems="stretch"
                  image={
                    <Box width="100%" align="right">
                      <Image
                        width="120px"
                        src="https://www.wix-pages.com/wix-design-system-employees/PromotionalBookingsUpgrade.svg"
                        transparent
                      />
                    </Box>
                  }
                />
              </Card>
            </Cell>
            <Cell span={12}>
              <Card>
                <MarketingLayout
                  size="tiny"
                  actions={
                    <Button
                      size="small"
                      priority="secondary"
                      suffixIcon={<Icons.WixForms />}
                      onClick={() => {
                        window.Intercom("startSurvey", 44499573);
                      }}
                    >
                      Share Feedback
                    </Button>
                  }
                  title="Looking for something else?"
                  description="Share your feedback with us"
                ></MarketingLayout>
              </Card>
            </Cell>
          </Layout>
        </Page.Content>
      </Page>
    </WixDesignSystemProvider>
  );
}

export default App;
